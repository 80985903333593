import {Logger} from 'babylonjs/Misc/logger';

export class SceneOptions {
    public skyBox: string;
}

export class SceneManager {

    public scene: BABYLON.Scene;

    constructor() {
        Logger.Log("Init designer scene manager");
    }

    public createNewScene(options: any, engine: BABYLON.Engine, callback: Function): void {
        let scene = new BABYLON.Scene(engine);
        if (callback) {
            callback(scene);
        }
    }

    public loadScene(source: string, engine: BABYLON.Engine, callback: Function): void {

    }

    public saveScene(scene: BABYLON.Scene, saveMeshCallback: Function, saveTextureCallback: Function, saveSceneCallback: Function): void {
        let sceneData = BABYLON.SceneSerializer.Serialize(scene);

        if (saveSceneCallback) {
            saveSceneCallback(sceneData);
        }
    }

    public importModel(scene: BABYLON.Scene, url: string, callback: Function): void {

    }
}