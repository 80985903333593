(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("babylonjs"));
	else if(typeof define === 'function' && define.amd)
		define("kingfisher-designer", ["babylonjs"], factory);
	else if(typeof exports === 'object')
		exports["kingfisher-designer"] = factory(require("babylonjs"));
	else
		root["DESIGNER"] = factory(root["BABYLON"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), function(__WEBPACK_EXTERNAL_MODULE_babylonjs_Misc_logger__) {
return 